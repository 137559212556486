export const importAll = function(context){
  var model = {}

  context.keys().map(ele =>{
    const keyReg = /[/]([^/:*?"<>|]+)\.js$/
		// console.log(keyReg.exec(ele));
    model[keyReg.exec(ele)[1]] = context(ele)
  })

  return model
}
 