import axios from 'axios'
import { Message } from 'element-ui'
export let BaseURL
if (process.env.NODE_ENV === 'development') {
  BaseURL = 'http://8.134.39.166:8086/'
} else if (process.env.NODE_ENV === 'production') {
  BaseURL = '/'
}
// export let BaseURL = '/' //http://8.134.39.166:8086/
let requst = axios.create({
  baseURL: BaseURL, //http://8.134.39.166:8086/
  timeout: 15000,
  headers: {
    'content-type': 'application/json'
  }
})
requst.interceptors.request.use(req => {
  return req
})
requst.interceptors.response.use(res => {
  if (res.data.returnCode != 1) {
    Message({ type: 'error', message: res.data.returnMsg })
  }
  return res.data
})
export default requst
