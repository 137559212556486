export default [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/pc/home/index.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/pc/product/index.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/views/pc/product/children/download.vue"),
  },
  {
    path: "/system",
    name: "system",
    component: () => import("@/views/pc/product/children/system.vue"),
  },
  {
    path: "/enterprise",
    name: "enterprise",
    component: () => import("@/views/pc/enterprise/index.vue"),
  },
  {
    path: "/example",
    name: "example",
    component: () => import("@/views/pc/example/index.vue"),
  },
  {
    path: "/interaction",
    name: "interaction",
    component: () => import("@/views/pc/interaction/index.vue"),
  },
  {
    path: "/trial",
    name: "trial",
    component: () => import("@/views/pc/trial/index.vue"),
  },
  {
    path: "/hotel",
    name: "hotel",
    component: () => import("@/views/pc/hotel/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/pc/about/about.vue"),
  },
  {
    path: "/us",
    name: "us",
    component: () => import("@/views/pc/about/us.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/pc/about/news.vue"),
  },
  {
    path: "/newdetails",
    name: "newdetails",
    component: () => import("@/views/pc/about/newDetails.vue"),
  },
  {
    path: "authOpenId",
    name: "authOpenId",
    component: () => import("@/views/pc/auth/index.vue"),
  },
];
