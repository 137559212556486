import Vue from 'vue'
import Router from 'vue-router'
import {importAll} from "@/utils"
import store from '../store/index.js'
Vue.use(Router)

//解决页面重复点击报错的问题 虽然没得影响但是报错直接在原型链上抓住
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


let modules = {}
let routes = []
var modules_keys="";
let isMobile =  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
if(!isMobile){
	modules = importAll(require.context('./pc',false,/\.js$/))
	modules_keys = Reflect.ownKeys(modules)
	// console.log(modules,modules_keys);
	routes = [{
		path:"/",
		component:()=>import("@/views/pc/index.vue"),
		redirect:"/home",
		children:[
				...modules[modules_keys].default
	 	]
	},
	{
		path:"/:pathMatch(.*)",
		redirect:"/"
	}]
}else{
	modules = importAll(require.context('./m',false,/\.js$/))
	modules_keys = Reflect.ownKeys(modules)
	routes = [{
		path:"/",
		redirect:"/m"
	},{
		path: '/m',
		name: 'm',
		component:()=>import("@/views/m/index.vue"),
		redirect:"/m/home",
		children:[
			...modules[modules_keys].default
		]
	}
	,{
		path:"/:pathMatch(.*)",
		redirect:"/m"
	}
	]
}

const router = new Router({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {
	let path= to.path.split('/')
	// console.log(store);
	switch(path[1]){
		case 'home': store.commit('setCurrindex',0);break;
		case 'product': store.commit('setCurrindex',1);break;
		case 'system': store.commit('setCurrindex',1);break;
		case 'download': store.commit('setCurrindex',1);break;
		case 'enterprise': store.commit('setCurrindex',2);break;
		default:store.commit('setCurrindex',100);break;
		// case 'trial': store.commit('setCurrindex',100);break;
	}
	// $nextTick(()=>{
		document.documentElement.scrollTop=0
	// })
  next()
});
export default router
