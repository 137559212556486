export default [
  {
    path: "home",
    name: "home",
    component: () => import("@/views/m/home/index.vue"),
  },
  {
    path: "product",
    name: "product",
    component: () => import("@/views/m/product/index.vue"),
  },
  {
    path: "download",
    name: "download",
    component: () => import("@/views/m/product/download.vue"),
  },
  {
    path: "system",
    name: "system",
    component: () => import("@/views/m/product/system.vue"),
  },
  {
    path: "enterprise",
    name: "enterprise",
    component: () => import("@/views/m/enterprise/index.vue"),
  },
  {
    path: "trial",
    name: "trial",
    component: () => import("@/views/m/trial/index.vue"),
  },
  {
    path: "about",
    name: "about",
    component: () => import("@/views/m/about/about.vue"),
  },
  {
    path: "us",
    name: "us",
    component: () => import("@/views/m/about/us.vue"),
  },
  {
    path: "news",
    name: "news",
    component: () => import("@/views/m/about/news.vue"),
  },
  {
    path: "newDetails",
    name: "newDetails",
    component: () => import("@/views/m/about/newDetails.vue"),
  },
  {
    path: "authOpenId",
    name: "authOpenId",
    component: () => import("@/views/m/auth/index.vue"),
  },
];
