import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import request from '@/utils/request.js'
import { Message } from 'element-ui'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    introduceList: [
      {
        name: [{ text: '产品中心' }, { text: '产品', path: '/product' }, { text: '产品体系', path: '/system' }, { text: '下载', path: '/download' }]
      },
      {
        name: [
          { text: '方案' },
          { text: '企业准入方案', path: '/enterprise' },
          { text: '定制开发', path: '/trial' },
          { text: '渠道合作', path: '/us' }
        ]
      },
      {
        name: [{ text: '关于我们' }, { text: '平台简介', path: '/about' }, { text: '新闻资讯', path: '/news' }, { text: '联系我们', path: '/about' }]
      }
    ],
    currindex: 0,
    casesList: [],
    versionList: [],
    version: {},
    userIngo: {},
    islogin: localStorage.getItem('loginUser') ? true : false,
    isfoot: true,
    newsList: [],
    newsTotal: 0,
    codeList: [],
    isAdver: false
  },
  mutations: {
    setCurrindex(state, index) {
      state.currindex = index
    },
    setCasesList(state, casesList) {
      state.casesList = casesList
    },
    setVersion(state, version) {
      state.version = version
    },
    setVersionList(state, versionList) {
      state.versionList = [...state.versionList, ...versionList]
    },
    setIsLogin(state, bool) {
      state.islogin = bool
    },
    setIsFoot(state, bool) {
      state.isfoot = bool
    },
    setNewsList(state, list) {
      state.newsList = list
    },
    setNewsTotal(state, total) {
      state.newsTotal = total
    },
    setCodeList(state, list) {
      state.codeList = list
    },
    setIsAdver(state, bool) {
      state.isAdver = bool
    }
  },
  actions: {
    async getCodeList({ commit }) {
      let result = await request.request({
        url: 'api/get_code',
        method: 'POST',
        data: { code: 'SALES_LINK' }
      })
      console.log(result, 'getcode')
      let array = result.data.split(',')
      let arr = []
      array.forEach(item => {
        let a = item.substr(0, 3)
        let b = item.substr(3, 4)
        let c = item.substr(7, 4)
        console.log(a, b, c)
        arr.push(a + '-' + b + '-' + c)
      })
      console.log(array, arr)
      commit('setCodeList', arr)
    },
    //获取案例
    async getCasesList({ commit, dispatch }) {
      let result = await request.request({
        url: 'api/cases',
        method: 'POST',
        data: { page: { size: 10, cur: 1 } }
      })
      if (result.returnCode == 1) {
        let arr = result.data.records
        commit('setCasesList', result.data.records)
      }
    },
    //获取版本列表
    async getVersionList({ commit }, data = { page: { size: 5, cur: 1 } }) {
      let result = await request({
        url: 'api/version',
        method: 'post',
        data
      })
      if (result.returnCode == 1) {
        let arr = result.data.records
        if (arr.length < data.page.size) {
          commit('setIsFoot', false)
        }
        if (data.page.cur == 1) commit('setVersion', arr.shift())
        commit('setVersionList', arr)
      }
    },
    //提交用户信息
    async submitUserInfo({ commit }, userInfo) {
      let res = await request.request({
        url: 'api/save_info',
        method: 'POST',
        data: userInfo
      })
      if (res.returnCode == 1) {
        Message({ type: 'success', message: '提交成功' })
        localStorage.setItem('loginUser', JSON.stringify(userInfo))
        commit('setIsLogin', true)
      }
    },
    //用户下载
    async statistics({ commit }, params) {
      let res = await request.request({
        url: 'api/version_record',
        method: 'POST',
        data: params
      })
    },
    // 获取新闻资讯
    async getNewsList({ commit }, parasms) {
      let result = await request.request({
        url: '/api/news',
        method: 'POST',
        data: parasms
      })
      let { data, returnCode } = result
      if (returnCode == '1') {
        let tableList = data.records
        tableList.forEach(item => {
          if (item.labelStr != '') {
            item.labelStr = item.labelStr.split(',')
          } else {
            item.labelStr = null
          }
          item.createTime = item.createTime.split(' ')[0].split('-')
        })
        commit('setNewsList', tableList)
        commit('setNewsTotal', data.total)
      }
    }
  },
  modules: {}
})
export default store
export function setup() {
  store.dispatch('getCasesList')
  store.dispatch('getVersionList')
  store.dispatch('getNewsList', { page: { cur: 0, size: 3 } })
}
