import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {setup} from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import "animate.css"
import BaiduMap from 'vue-baidu-map'
import $http from './utils/request.js'
Vue.prototype.$http = $http;
// setup()
import vueLazyload from 'vue-lazyload'
 Vue.use(vueLazyload);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(BaiduMap,{ak:'QWXqHXpL41z8hHDwqoqA8sf3spj8z5q1&v=2.0'})
new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    this.getSEO()
  },
  data:{
    title: '\u65e0\u7ebf\u7f51\u7edc\u8ba4\u8bc1\uff5c\u0057\u0049\u0046\u0049\u51c6\u5165\u0020\u007c\u0020\u7f51\u7edc\u51c6\u5165\u7cfb\u7edf\uff5c\u6e56\u5357\u4f18\u4eab\u4e91\u901a\u4fe1\u6280\u672f\u6709\u9650\u516c\u53f8',
    description: '\u6e56\u5357\u4f18\u4eab\u4e91\u901a\u4fe1\u6280\u672f\u6709\u9650\u516c\u53f8\u5f00\u53d1\u7684\u65e0\u7ebf\u51c6\u5165\u7cfb\u7edf\uff0c\u652f\u6301\u534e\u4e3a\u005c\u0048\u0033\u0043\u005c\u9510\u6377\u005c\u0052\u0055\u0043\u004b\u0055\u0053\u005c\u0041\u0052\u0055\u0042\u0041\u7b49\u4e3b\u6d41\u8bbe\u5907\uff0c\u652f\u6301\u6807\u51c6\u0050\u006f\u0072\u0074\u0061\u006c\u534f\u8bae\u3001\u0043\u004d\u0043\u0043\u534f\u8bae\u3001\u0050\u0041\u0050\u0020\u0043\u0048\u0041\u0050\u8ba4\u8bc1\u65b9\u5f0f\u3002\u5b9e\u73b0\u9875\u9762\u5e7f\u544a\u5c55\u793a\u3001\u5fae\u4fe1\u516c\u4f17\u53f7\u8ba4\u8bc1\u3001\u5feb\u6377\u8ba4\u8bc1\u3001\u9489\u9489\u8ba4\u8bc1\u3001\u77ed\u4fe1\u8ba4\u8bc1\u3001\u4e8c\u7ef4\u7801\u8ba4\u8bc1\u3001\u5458\u5de5\u6388\u6743\u8ba4\u8bc1\u3001\u7528\u6237\u5bc6\u7801\u8ba4\u8bc1\uff0c\u652f\u6301\u7b2c\u4e09\u65b9\u0052\u0061\u0064\u0069\u0075\u0073\u5bf9\u63a5\uff01',
    keywords: '\u5fae\u4fe1\u516c\u4f17\u53f7\u8ba4\u8bc1\u0020\u007c\u0020\u65e0\u7ebf\u7f51\u7edc\u8ba4\u8bc1\uff5c\u0057\u0049\u0046\u0049\u51c6\u5165\u0020\u007c\u0020\u7ec8\u7aef\u51c6\u5165\uff5c\u7edf\u4e00\u8eab\u4efd\u7ba1\u7406\u0020\u007c\u0020\u8bbf\u95ee\u63a7\u5236\u7ba1\u7406\u0020\u007c\u0020\u51c6\u5165\u63a7\u5236\u7ba1\u7406\u0020\u007c\u0020\u0050\u006f\u0072\u0074\u0061\u006c\u8ba4\u8bc1\u0020\u007c\u0020\u0077\u0065\u0062\u8ba4\u8bc1'
  },
  methods: {
    getSEO(){
      document.title = this.title || '优享云通信';
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', this.description)
    }
  }
}).$mount('#app')
